<template>
  <ContentWrapper>
    <breadcrumb name="Ausverkauf"/>
      <div class="container animated-fast fadeInUp">

        <h1 class="my-4 text-center">Verkauf der letzten 5.500 Pflanzen <br/> Eine besondere Gelegenheit 🌿</h1>

        <p>Liebe Pflanzenbesitzer,</p>

        <p>Zuerst einmal ein großes Dankeschön an euch – für euer Vertrauen, eure Unterstützung und die vielen positiven Nachrichten, die uns jeden Tag erreichen.</p>

        <p>Nach einer längeren Phase ohne Pflanzenverkauf möchten wir euch nun informieren, dass wir <strong>5.500 Pflanzen</strong> aus Rückgaben und Rückkäufen der letzten fünf Jahre zum Verkauf anbieten. Da wir bereits mitten in der Umstellung auf den <strong>THC-Anbau</strong> sind, wird dies die letzte Gelegenheit sein, Pflanzen bei CannerGrow zu erwerben.</p>

        <div class="p-5">
          <div style="position: relative; padding-top: 56.25%;">
            <iframe
                src="https://customer-awha6m8cb6io3i7q.cloudflarestream.com/a3c2f778da61c99b0759221714286aef/iframe?poster=https%3A%2F%2Fcustomer-awha6m8cb6io3i7q.cloudflarestream.com%2Fa3c2f778da61c99b0759221714286aef%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600"
                loading="lazy"
                style="border: none; position: absolute; top: 0; left: 0; height: 100%; width: 100%;"
                allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                allowfullscreen="true"
            ></iframe>
          </div>
        </div>

        <h2 class="mt-5">Warum jetzt kaufen?</h2>

        <p>Mit unserem Fokus auf den THC-Anbau planen wir keine weiteren Verkäufe von Pflanzen. Diese einmalige Aktion war nicht geplant, aber wir möchten die verbliebenen Bestände an unsere treue Community weitergeben. Tatsächlich haben wir seit sehr langer Zeit keine Pflanzen mehr an Kunden verkauft, und dies ist eure letzte Chance, Pflanzen direkt von uns zu erwerben.</p>

        <!-- Accordion Cards -->
        <accordion-card>
          <template v-slot:title>
            <i class="fad fa-boxes mx-1"></i> Unser Ansatz: Kein fester Preis
          </template>

          <p>Anstatt einen festen Preis für die Pflanzen festzulegen, könnt ihr <strong>Tickets</strong> für <strong>1.000 € (inkl. MwSt.)</strong> erwerben. Jedes Ticket sichert euch einen Anteil an den insgesamt <strong>5.000 Pflanzen</strong>. Die genaue Anzahl der Pflanzen, die ihr erhaltet, hängt von der Gesamtzahl der verkauften Tickets ab.</p>
        </accordion-card>

        <accordion-card>
          <template v-slot:title>
            <i class="fad fa-gift mx-1"></i> Bonus für frühe Käufer
          </template>

          <p>Die ersten <strong>500 Ticketkäufer</strong> erhalten zusätzlich <strong>eine Pflanze gratis</strong>. Wenn ihr also <strong>10 Tickets</strong> kauft und unter den ersten 500 Käufern seid, bekommt ihr eine zusätzliche Pflanze als Bonus.</p>
        </accordion-card>

        <accordion-card>
          <template v-slot:title>
            <i class="fad fa-seedling mx-1"></i> Verteilung der Pflanzen
          </template>

          <p>Am Ende des Verkaufs am <strong>20. Dezember</strong> werden die Pflanzen gleichmäßig unter allen Ticketkäufern aufgeteilt. Hier sind einige Beispiele, um den Ablauf zu verdeutlichen:</p>

          <h4>Beispiel 1:</h4>
          <ul>
            <li><strong>Verkaufte Tickets:</strong> 1.000</li>
            <li><strong>Pflanzen pro Ticket:</strong> 5.000 Pflanzen ÷ 1.000 Tickets = 5 Pflanzen pro Ticket</li>
            <li><strong>Euer Anteil:</strong>
              <ul>
                <li>Mit 1 Ticket erhaltet ihr 5 Pflanzen</li>
                <li>Preis pro Pflanze: 1.000 € ÷ 5 Pflanzen = 200 € pro Pflanze</li>
                <li>Mit 10 Tickets erhaltet ihr 50 Pflanzen</li>
                <li>Preis pro Pflanze: 10.000 € ÷ 50 Pflanzen = 200 € pro Pflanze</li>
                <li>Plus 1 Bonuspflanze, wenn ihr unter den ersten 500 Käufern wart.</li>
              </ul>
            </li>
          </ul>

          <h4>Beispiel 2:</h4>
          <ul>
            <li><strong>Verkaufte Tickets:</strong> 3.000</li>
            <li><strong>Pflanzen pro Ticket:</strong> 5.000 Pflanzen ÷ 3.000 Tickets = 1,66 Pflanzen pro Ticket</li>
            <li><strong>Euer Anteil:</strong>
              <ul>
                <li>Mit 1 Ticket erhaltet ihr 1 Pflanze (abgerundet)</li>
                <li>Mit 10 Tickets erhaltet ihr 16 Pflanzen (abgerundet)</li>
                <li>Preis pro Pflanze: 10.000 € ÷ 16 Pflanzen = 625 € pro Pflanze</li>
                <li>Plus 1 Bonuspflanze, wenn ihr unter den ersten 500 Käufern wart.</li>
              </ul>
            </li>
          </ul>
        </accordion-card>

        <accordion-card>
          <template v-slot:title>
            <i class="fad fa-calendar-alt mx-1"></i> Verkaufs-Etappen und Teilnahme
          </template>

          <ul>
            <li><strong> ab dem 25. Oktober – 20. Dezember:</strong> Offen für alle Nutzer, die sich vor dem Verkaufsstart registriert haben.</li>
          </ul>
         </accordion-card>

        <hr class="my-5">


        <h2>Zwei zukunftsweisende Projekte bei Cannerald AG</h2>

        <p>Die <strong>Cannerald AG</strong> hat aktuell <strong>zwei enorm spannende und zukunftsweisende Projekte</strong>, die wir mit euch gemeinsam angehen wollen.</p>

        <p><strong>Diese beiden Projekte werden durch den Verkauf der Tickets gestartet.</strong> Mit dem Kauf von Tickets unterstützt ihr nicht nur den Erwerb der letzten Pflanzen, sondern tragt auch maßgeblich zur Realisierung dieser zukunftsweisenden Projekte bei. Ihr habt somit die Möglichkeit, diese Unternehmungen aktiv zu unterstützen und Teil dieser spannenden Entwicklungen zu sein.</p>

        <accordion-card>
          <template v-slot:title>
            <i class="fad fa-flask mx-1"></i> 1. Innovatives Biotech-Spin-off
          </template>

          <p>Wir planen ein <strong>Spin-off im Biotech-Bereich</strong>, das sich auf die Bekämpfung einer Krankheit fokussiert, die weltweit kontinuierlich im Mittelpunkt des Interesses steht. Mit modernsten Technologien und innovativen Ansätzen soll dieses neue Unternehmen entscheidende Fortschritte in der medizinischen Forschung und Behandlung erzielen. Unser Ziel ist es, <strong>neue Wege in der Prävention und Heilung</strong> zu beschreiten, um einen globalen gesundheitlichen Beitrag zu leisten.</p>
        </accordion-card>

        <accordion-card>
          <template v-slot:title>
            <i class="fad fa-vials mx-1"></i> 2. Eigenes Extraktionslabor
          </template>

          <p>Ein zentraler Bestandteil dieses Vorhabens ist die <strong>Errichtung eines eigenen Extraktionslabors</strong>, das es der Cannerald AG ermöglicht, unabhängig und effizient zu arbeiten. Dieses Projekt erfordert bedeutende Investitionen, die wir tätigen werden, um den gesamten Extraktionsprozess unter eigener Kontrolle zu haben. Dies eröffnet uns neue Wege der Produktentwicklung und steigert die Flexibilität und Qualität unserer Ergebnisse.</p>
        </accordion-card>

        <hr class="my-5">

        <TicketStats></TicketStats>

      </div>
  </ContentWrapper>
</template>

<style lang="scss">
</style>

<script>

import AccordionCard from "../../components/AccordionCard.vue";
import TicketStats from "../../components/TicketStats.vue";
export default {
  components: {
    AccordionCard,
    TicketStats
  },
  data() {
    return {
      product: null,
    };
  },
  beforeCreate() {
    if (this.$store.getters.user('country') !== 'CH') {
      this.$router.push({name: 'Dashboard'});
    }
  },
  methods: {

  }
}
</script>
