<template>

  <div>

    <div class="card ">
      <div class="card-body">

        <div class="ticket-calculator">

          <!-- Titel -->
          <h3 class="text-center mb-4">
            <i class="fas fa-calculator mr-2"></i> Tickets kaufen
          </h3>
          <p class="text-center">
            Gib die Anzahl der Tickets ein, die du kaufen möchtest, um zu
            berechnen, wie viele Pflanzen du erhalten würdest.
          </p>

          <!-- Bootstrap Row für zwei Spalten -->
          <div class="row">
            <!-- Erste Spalte: Eingabe und Warnungen -->
            <div class="col-md-6 mb-4">
              <!-- Eingabefeld -->
              <div class="form-group">
                <label for="ticketsToBuy">
                  <i class="fas fa-ticket-alt mr-1"></i> Anzahl der Tickets, die du kaufen möchtest:
                </label>
                <div class="input-group input-group-lg">
                  <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fas fa-ticket-alt"></i>
                </span>
                  </div>
                  <input
                      type="number"
                      class="form-control"
                      id="ticketsToBuy"
                      v-model.number="ticketsToBuy"
                      :max="maxTickets"
                      min="1"
                      placeholder="Gib die Anzahl der Tickets ein"
                  />
                </div>
                <small class="form-text text-muted">
                  Maximale Anzahl an Tickets: {{ maxTickets }}
                </small>
              </div>

              <!-- Warnung bei Überschreitung der maximalen Tickets -->
              <div v-if="ticketsToBuy > maxTickets" class="text-danger">
                <i class="fas fa-exclamation-circle mr-1"></i>
                Du kannst maximal {{ maxTickets }} Tickets kaufen.
              </div>

              <!-- Hinweis zur Zahlungsfrist -->
              <div class="alert alert-warning mt-3" role="alert">
                <i class="fas fa-exclamation-circle mr-1"></i>
                Bitte beachte: Deine Bestellung muss innerhalb von 5 Tagen bezahlt werden, bevor du weitere Tickets
                kaufen kannst.
              </div>

              <!-- Hinweise zur Wertänderung -->
              <div class="alert alert-warning" role="alert">
                <i class="fas fa-exclamation-triangle mr-1"></i>
                Hinweis: Wenn es mehr Bestellungen gibt, sinkt die Anzahl der Pflanzen pro Ticket, und der Preis pro
                Pflanze steigt entsprechend.
              </div>
            </div>

            <!-- Zweite Spalte: Berechnungsergebnisse und Hinweise -->
            <div class="col-md-6">
              <!-- Berechnungsergebnisse -->
              <div v-if="ticketsToBuy > 0 && ticketsToBuy <= maxTickets" class="mt-4">
                <div class="alert border" role="alert">
                  <h5 class="alert-heading">
                    <i class="fas fa-info-circle mr-2"></i> Deine Berechnung
                  </h5>
                  <p>
                    Wenn du <strong>{{ ticketsToBuy }}</strong> Tickets kaufst, gibt es insgesamt (inkl. deiner
                    Bestellung) <strong>{{ totalTickets }}</strong> Tickets. Wenn kein weiterer Kunde ein Ticket kauft, erhältst du pro Ticket etwa
                    <strong>{{ plantsPerTicket }}</strong> Pflanzen.
                  </p>
                  <p>
                    Das bedeutet, du würdest insgesamt ungefähr <strong>{{ totalPlantsForPurchase }}</strong> Pflanzen
                    erhalten.
                  </p>
                  <p>
                    Nach Abrundung sind das <strong>{{ actualPlants }}</strong> Pflanzen, die dir tatsächlich zugeteilt
                    werden.
                  </p>
                  <p>
                    Der Preis pro Pflanze liegt dann bei ungefähr <strong>{{ pricePerPlant }} €</strong>.
                  </p>
                  <div class="alert alert-primary">
                    <strong class="text-secondary">Bonuspflanze: </strong>

                    <span v-if="bonusPlants > 0">
                      Du erhältst beim kauf von Tickets eine Bonuspflanze (limitiert auf 1 pro Kunde)! Es sind noch <b class="text-secondary">{{ bonusPlants }}</b> verfügbar.
                    </span>
                    <span v-if="bonusPlants === 0">
                      Nein, alle Bonuspflanzen sind vergeben.
                    </span>

                  </div>
                </div>


              </div>

              <!-- Fehleranzeige, wenn TicketsToBuy > maxTickets -->
              <div v-else-if="ticketsToBuy > maxTickets" class="alert alert-danger mt-4" role="alert">
                <i class="fas fa-exclamation-triangle mr-1"></i>
                Du kannst maximal {{ maxTickets }} Tickets kaufen.
              </div>
            </div>
          </div>

          <!-- Beispiele zur Verteilung der Pflanzen -->
          <div v-if="ticketsToBuy > 0 && ticketsToBuy <= maxTickets" class="mt-2">
            <h5 class="mb-3">
              <i class="fas fa-exclamation-circle mr-1"></i> Beispiele zur Verteilung der Pflanzen
            </h5>
            <accordion-card>
              <template v-slot:title>
                <i class="fas fa-boxes mx-1"></i> Beispiel 1
              </template>
              <p>
                <strong>Verkaufte Tickets:</strong> 1.000<br/>
                <strong>Pflanzen pro Ticket:</strong> 5.000 Pflanzen ÷ 1.000 Tickets = 5 Pflanzen pro Ticket<br/>
                <strong>Dein Anteil:</strong><br/>
                - Mit 1 Ticket erhältst du 5 Pflanzen<br/>
                - Preis pro Pflanze: 1.000 € ÷ 5 Pflanzen = 200 € pro Pflanze<br/>
                - Mit 10 Tickets erhältst du 50 Pflanzen<br/>
                - Preis pro Pflanze: 10.000 € ÷ 50 Pflanzen = 200 € pro Pflanze<br/>
                - Plus 1 Bonuspflanze, wenn du unter den ersten 500 Käufern warst.
              </p>
            </accordion-card>

            <accordion-card>
              <template v-slot:title>
                <i class="fas fa-boxes mx-1"></i> Beispiel 2
              </template>
              <p>
                <strong>Verkaufte Tickets:</strong> 3.000<br/>
                <strong>Pflanzen pro Ticket:</strong> 5.000 Pflanzen ÷ 3.000 Tickets = 1,66 Pflanzen pro Ticket<br/>
                <strong>Dein Anteil:</strong><br/>
                - Mit 1 Ticket erhältst du 1 Pflanze (abgerundet)<br/>
                - Mit 10 Tickets erhältst du 16 Pflanzen (abgerundet)<br/>
                - Preis pro Pflanze: 10.000 € ÷ 16 Pflanzen = 625 € pro Pflanze<br/>
                - Plus 1 Bonuspflanze, wenn du unter den ersten 500 Käufern warst.
              </p>
            </accordion-card>
          </div>

        </div>

        <hr class="my-5">

        <div v-if="product">
          <payment-selector
              :product="product"
              :quantity="ticketsToBuy"
              :quantity-preselected="1"
              :quantity-selector="false"
              :terms="product.agreement.url"
          ></payment-selector>
        </div>

      </div>
    </div>

  </div>
</template>

<style lang="scss">
.ticket-calculator {
  width: 100%;
  padding: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: #ffffff;

  .input-group-lg .input-group-text {
    font-size: 1.5rem;
    background-color: #f0f0f0;
    border-right: none;
    border-radius: 0.25rem 0 0 0.25rem;
  }

  .form-group label {
    font-weight: bold;
    display: flex;
    align-items: center;
  }

  .input-group .form-control {
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    border-left: none;
    border-radius: 0 0.25rem 0.25rem 0;
  }

}
</style>

<script>

import AccordionCard from "./AccordionCard.vue";
import PaymentSelector from "@/modules/Wallet/components/PaymentSelector.vue";

export default {
  components: {
    PaymentSelector,
    AccordionCard,
  },
  data() {
    return {
      product: null,
      totalPlants: 5000,       // Gesamtzahl der verfügbaren Pflanzen
      ticketPrice: 1000,        // Preis pro Ticket in €
      ticketsSold: 0,           // Anzahl der bereits verkauften Tickets (von der API)
      ticketsToBuy: 1,          // Anzahl der Tickets, die du kaufen möchtest
      bonusPlants: 0,
      maxTickets: 100,          // Maximale Anzahl an Tickets, die gekauft werden können
    };
  },
  computed: {
    totalTickets() {
      return parseInt(this.ticketsSold) + parseInt(this.ticketsToBuy);
    },
    plantsPerTicket() {
      if (this.totalTickets > 0) {
        return (this.totalPlants / this.totalTickets).toFixed(2);
      }
      return 0;
    },
    totalPlantsForPurchase() {
      return (this.ticketsToBuy * this.plantsPerTicket).toFixed(2);
    },
    actualPlants() {
      return Math.floor(this.totalPlantsForPurchase);
    },
    pricePerPlant() {
      if (this.plantsPerTicket > 0) {
        return (this.ticketPrice / this.plantsPerTicket).toFixed(2);
      }
      return 0;
    },
    bonusPlant() {
      return this.bonusPlants > 0;
    },
  },
  methods: {

  },
  beforeCreate() {
    this.$api.get("growing/plants/product/23").then((response) => {
      this.product = response.data.product;
    });

    this.$api.get("growing/ticket-sale").then((response) => {
      this.ticketsSold = response.data.tickets_sold;
      this.bonusPlants = response.data.bonus_plants_available;
      if (!response.data.has_swapped_plants) {
        this.$router.push({name: 'Dashboard'});
      }
    });
  },
};
</script>
